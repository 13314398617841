<template>
  <div
    class="d-flex align-items-center"
    style="gap: 12px; background-color: #e5eaee; padding: 4px 8px; border-radius: 8px; position: relative"
  >
    <div v-for="icon in icons" :key="icon.field" v-b-tooltip.hover :title="icon.tooltipTitle">
      <div v-if="getValue(icon) === null">-</div>
      <i v-else :class="iconClasses(icon)"></i>
      <i
        v-if="passdatenStar(icon) === false"
        class="fas fa-asterisk text-danger icon-sm"
        style="align-self: start; position: absolute"
        title="Passdaten nicht vollständig"
      ></i>
    </div>
  </div>
</template>
<script>
import { releaseprozessIconClass } from '@/core/common/helpers/utils';

export default {
  data() {
    return {
      icons: [
        {
          field: 'teilnehmerErledigt',
          icon: 'fas fa-user-check',
          extra: 'passdatenStar',
          tooltipTitle: 'Teilnehmerdaten',
        },
        {
          field: 'reiseleistungListenversandErledigt',
          icon: 'fas fa-suitcase fa-lg',
          tooltipTitle: 'Reiseleistung und Listenversand',
        },
        {
          field: 'fluegePassend',
          icon: 'fas fa-plane fa-lg',
          tooltipTitle: 'Flüge und Flugtickets',
        },
        {
          field: 'transferplanungErledigt',
          icon: 'fas fa-car fa-lg',
          tooltipTitle: 'Transferplanung Vorgangsebene',
        },
        {
          field: 'reiseunterlagenGeprueft',
          icon: 'far fa-list-alt fa-lg',
          type: 'reisetermin',
          tooltipTitle: 'Reiseunterlagen',
        },
        {
          field: 'transferplanungGeprueft',
          icon: 'fas fa-bus fa-lg',
          type: 'reisetermin',
          tooltipTitle: 'Finale Transferplanung',
        },
      ],
    };
  },
  props: {
    reiseterminObject: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getValue(icon) {
      return icon.type === 'reisetermin'
        ? this.reiseterminObject?.[icon.field]
        : this.reiseterminObject?.vorgangReleaseprozess?.[icon.field];
    },
    iconClasses(icon) {
      const params = {
        attribute: icon.field,
        icon: icon.icon,
        value:
          icon.type === 'reisetermin'
            ? this.reiseterminObject?.[icon.field]
            : this.reiseterminObject?.vorgangReleaseprozess?.[icon.field],
      };
      if (icon.field === 'fluegePassend') {
        const color =
          this.reiseterminObject?.vorgangReleaseprozess.fluegePassend &&
          this.reiseterminObject?.vorgangReleaseprozess.flugticketsAusgestellt
            ? 'text-success'
            : this.reiseterminObject?.vorgangReleaseprozess.fluegePassend ||
              this.reiseterminObject?.vorgangReleaseprozess.flugticketsAusgestellt
            ? 'text-warning'
            : 'text-danger';
        return icon.icon + ' mr-1 ' + color;
      }
      return releaseprozessIconClass(params);
    },
    releaseprozessIconClass,
    passdatenStar(icon) {
      if (icon?.extra === 'passdatenStar') {
        return this.reiseterminObject?.vorgangReleaseprozess?.configurationFulfilledForAllTeilnehmer;
      } else return null;
    },
  },
};
</script>
