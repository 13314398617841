<template>
  <div>
    <div>
      <div style="display: flex">
        <ActionButton
          class="mr-3"
          v-b-toggle.reisetermindaten-collapse
          title="Ausklappen"
          :iconClass="isCollapseOpen ? 'ki ki-arrow-up' : 'ki ki-arrow-down'"
          @click="toggleCollapse"
        />
        <h4>Reisetermindaten</h4>
      </div>
    </div>

    <b-collapse visible id="reisetermindaten-collapse">
      <div v-if="reiseterminObj">
        <div class="gutter-b">
          <b-table
            style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
            head-variant="light"
            responsive
            :fields="fieldsReisetermindatenTable"
            :items="[reiseterminObj]"
          >
            <template v-slot:cell(reisezeitraum)="row">
              {{ reisezeitraumComputed || '-' }}
            </template>
            <template v-slot:cell(pax)="row">
              <PaxWidget
                :paxObject="reiseterminObj.pax"
                :kalkPax="reiseterminObj.metadata.kalkPax"
              ></PaxWidget>
            </template>
            <template v-slot:cell(garantiert)="row">
              <i v-if="row.item.garantiert" class="fas fa-check text-success"></i>
              <i v-else class="fas fa-times text-danger"></i>
            </template>
            <template v-slot:cell(vorzeitigGarantiert)="row">
              <i
                v-if="row.item.vorzeitigGarantiert"
                :class="vorzeitigGarantiertClass(reiseterminObj.vorzeitigGarantiert)"
              ></i>
              <span v-else>-</span>
            </template>
            <template v-slot:cell(stopSale)="row">
              <i v-if="row.item.stopSale" class="fas fa-exclamation text-danger"></i>
              <span v-else>-</span>
            </template>
            <template v-slot:cell(isKritischeDestination)="row">
              <i v-if="row.item.metadata.isKritischeDestination" class="fas fa-exclamation text-danger"></i>
              <span v-else>-</span>
            </template>
          </b-table>
        </div>

        <div class="separator separator-solid mb-2 mt-2"></div>
        <ReiseterminViewBesonderheitenBase
          v-if="reiseterminObj"
          :besonderheiten="reiseterminObj.besonderheiten"
          :reisekuerzel="reiseterminObj.reise.reisekuerzel"
          :startdatum="reiseterminObj.startdatum"
          class="mt-4"
        ></ReiseterminViewBesonderheitenBase>
        <b-skeleton v-else width="100%" height="39px"></b-skeleton>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import ActionButton from '@/components/common/action-button.vue';
import { formatDateWithoutHours } from '@/core/common/helpers/utils.js';
const moment = require('moment');
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import { vorzeitigGarantiertClass } from '@/core/common/helpers/utils';
import ReiseterminViewBesonderheitenBase from '@/components/produkte/reisetermine/besonderheiten-base.vue';
import { subDays, format } from 'date-fns';

export default {
  props: {
    reiseterminObj: { type: Object },
  },
  components: {
    ActionButton,
    PaxWidget,
    ReiseterminViewBesonderheitenBase,
  },
  data() {
    return {
      isCollapseOpen: true,
      fieldsReisetermindatenTable: [
        {
          tdClass: 'align-middle',
          label: 'Releasefristen',
          key: 'releasefrist',
          formatter: (value, key, item) =>
            item.metadata?.releasefrist?.length > 0
              ? this.formatReleasefristen(item.metadata.releasefrist, item.startdatum)
              : '-',
        },
        {
          tdClass: 'align-middle',
          label: 'Versand der Unterlagen',
          key: 'unterlagenversandDatum',
          formatter: value => (value ? formatDateWithoutHours(value) : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'abreisedatum',
          label: 'Abreise',
          formatter: value => (value ? formatDateWithoutHours(value) : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'startdatum',
          label: 'Startdatum',
          formatter: value => (value ? formatDateWithoutHours(value) : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'reisezeitraum',
          label: 'Reisezeitraum',
        },
        {
          tdClass: 'align-middle',
          key: 'reisedauer',
          label: 'Reisedauer',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'pax',
          label: 'Pax',
        },
        {
          tdClass: 'align-middle',
          key: 'garantiert',
          label: 'Garantiert',
        },
        {
          tdClass: 'align-middle',
          key: 'vorzeitigGarantiert',
          label: 'Vorz. garantiert',
        },
        {
          tdClass: 'align-middle',
          key: 'stopSale',
          label: 'Stop Sale',
        },
        {
          tdClass: 'align-middle',
          label: 'Kritische Destination',
          key: 'isKritischeDestination',
        },
      ],
    };
  },
  computed: {
    reisezeitraumComputed() {
      if (!this.reiseterminObj) return '-';
      const reisedauer = this.reiseterminObj?.reisedauer ? this.reiseterminObj?.reisedauer - 1 : 0;
      const momentUntilDate = moment(this.reiseterminObj.abreisedatum).add(reisedauer, 'days');
      return this.reiseterminObj?.abreisedatum
        ? `${this.$options.filters.date(this.reiseterminObj.abreisedatum)} -  ${this.$options.filters.date(
            momentUntilDate
          )}`
        : '-';
    },
  },
  methods: {
    vorzeitigGarantiertClass,
    toggleCollapse() {
      this.isCollapseOpen = !this.isCollapseOpen;
    },
    formatAndCalculateReleasedatum(datum, releasefrist) {
      const startdatum = new Date(datum);
      const generatedDatum = subDays(startdatum, releasefrist);
      return format(generatedDatum, 'dd.MM.yyyy');
    },
    formatReleasefristen(releasefristenArray, startdatum) {
      return releasefristenArray
        .map(
          releasefrist => `${releasefrist} (${this.formatAndCalculateReleasedatum(startdatum, releasefrist)})`
        )
        .join(' / ');
    },
  },
};
</script>

<style></style>
