<template>
  <div>
    <div>
      <div>
        <h4>Reisetermindaten</h4>
      </div>
    </div>

    <div v-if="reiseterminObj">
      <div class="gutter-b">
        <b-table
          style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
          head-variant="light"
          responsive
          :fields="fieldsReisetermindatenTable"
          :items="[reiseterminObj]"
        >
          <template v-slot:cell(reisezeitraum)="">
            {{ reisezeitraumComputed || '-' }}
          </template>
          <template v-slot:cell(pax)="">
            <PaxWidget :paxObject="reiseterminObj.pax" :kalkPax="reiseterminObj.metadata.kalkPax"></PaxWidget>
          </template>
          <template v-slot:cell(garantiert)="row">
            <i v-if="row.item.garantiert" class="fas fa-check text-success"></i>
            <i v-else class="fas fa-times text-danger"></i>
          </template>
          <template v-slot:cell(vorzeitigGarantiert)="row">
            <i
              v-if="row.item.vorzeitigGarantiert"
              :class="vorzeitigGarantiertClass(reiseterminObj.vorzeitigGarantiert)"
            ></i>
            <span v-if="row.item.vorzeitigGarantiert == null">-</span>
          </template>
          <template v-slot:cell(stopSale)="row">
            <i v-if="row.item.stopSale" class="fas fa-exclamation text-danger"></i>
            <span v-else>-</span>
          </template>
          <template v-slot:cell(isKritischeDestination)="row">
            <i v-if="row.item.metadata.isKritischeDestination" class="fas fa-exclamation text-danger"></i>
            <span v-else>-</span>
          </template>
          <template v-slot:cell(metadata.hotelstartVorLandung)="row">
            <i v-if="row.item.metadata.hotelstartVorLandung" class="fas fa-check text-success"></i>
            <i v-else class="fas fa-times text-danger"></i>
          </template>
          <template v-slot:cell(metadata.rueckflugNachHotelende)="row">
            <i v-if="row.item.metadata.rueckflugNachHotelende" class="fas fa-check text-success"></i>
            <i v-else class="fas fa-times text-danger"></i>
          </template>
          <template v-slot:cell(ziellandZielflughaefen)="row">
            {{ row.item.metadata.ziellandZielflughaefen?.map(fh => fh.flughafenIataCode).join(', ') }}
          </template>
          <template v-slot:cell(ziellandAbflughaefen)="row">
            {{ row.item.metadata.ziellandAbflughaefen?.map(fh => fh.flughafenIataCode).join(', ') }}
          </template>
        </b-table>
      </div>

      <div class="separator separator-solid mt-2 mb-2"></div>

      <ReiseterminViewBesonderheitenBase
        v-if="reiseterminObj"
        :besonderheiten="reiseterminObj.besonderheiten"
        :reisekuerzel="reiseterminObj.reise.reisekuerzel"
        :startdatum="reiseterminObj.startdatum"
        class="mt-4"
      ></ReiseterminViewBesonderheitenBase>
      <b-skeleton v-else width="100%" height="39px"></b-skeleton>
    </div>
  </div>
</template>

<script>
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import ReiseterminViewBesonderheitenBase from '@/components/produkte/reisetermine/besonderheiten-base.vue';
import { getInBlankAngelegt, vorzeitigGarantiertClass } from '@/core/common/helpers/utils';
const moment = require('moment');
import { SET_AUFGABE_DETAIL_DATA } from '@/core/aufgaben/stores/aufgaben.module';
import { formatDateWithoutHours } from '@/core/common/helpers/utils.js';

import { subDays, format } from 'date-fns';

export default {
  components: {
    PaxWidget,
    ReiseterminViewBesonderheitenBase,
  },
  props: {
    reiseterminObj: Object,
    isInitialLoading: Boolean,
  },
  data() {
    return {
      fieldsReisetermindatenTable: [
        {
          tdClass: 'align-middle',
          label: 'Releasefristen',
          key: 'releasefrist',
          formatter: (value, key, item) =>
            item.metadata?.releasefrist
              ? this.formatReleasefristen(item.metadata.releasefrist, item.startdatum)
              : '-',
        },
        {
          tdClass: 'align-middle',
          label: 'Versand der Unterlagen',
          key: 'unterlagenversandDatum',
          formatter: value => (value ? formatDateWithoutHours(value) : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'abreisedatum',
          label: 'Abreise',
          formatter: value => (value ? formatDateWithoutHours(value) : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'startdatum',
          label: 'Startdatum',
          formatter: value => (value ? formatDateWithoutHours(value) : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'reisezeitraum',
          label: 'Reisezeitraum',
        },
        {
          tdClass: 'align-middle',
          key: 'reisedauer',
          label: 'Reisedauer',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'pax',
          label: 'Pax',
        },
        {
          tdClass: 'align-middle',
          key: 'garantiert',
          label: 'Garantiert',
        },
        {
          tdClass: 'align-middle',
          key: 'vorzeitigGarantiert',
          label: 'Vorz. garantiert',
        },
        {
          tdClass: 'align-middle',
          key: 'stopSale',
          label: 'Stop Sale',
        },
        {
          tdClass: 'align-middle',
          label: 'Kritische Destination',
          key: 'isKritischeDestination',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.overnightOutbound',
          label: 'OB',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.overnightInbound',
          label: 'IB',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.hotelstartVorLandung',
          label: 'Hotelstart v. Landung',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.rueckflugNachHotelende',
          label: 'Rückflug n. Hotelende',
        },
        {
          tdClass: 'align-middle',
          key: 'ziellandZielflughaefen',
          label: 'ZL Zielflughafen',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'ziellandAbflughaefen',
          label: 'ZL Abflughafen',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle ',
          key: 'metadata.transferszenarioHin',
          label: 'Transferszenario Hin',
          formatter: value => (value ? value.substring(0, 2) : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.transferszenarioRueck',
          label: 'Transferszenario Rück',
          formatter: value => (value ? value.substring(0, 2) : '-'),
        },
      ],
    };
  },
  computed: {
    kontaktdatenlisteLink() {
      if (!this.reiseterminObj) {
        return null;
      }
      const startdatumYear = new Date(this.reiseterminObj.startdatum).getFullYear();
      return startdatumYear === 2022
        ? 'https://docs.google.com/spreadsheets/d/1jLZQswDFvOvXLT9qlg44QPP5tDp0mcvt5z0PYof_GG8/edit?usp=sharing'
        : startdatumYear === 2023
        ? 'https://docs.google.com/spreadsheets/d/1ISyLNU-nPPi_M7Yrtm_r55Q789Y_9xdpUlRXZpbW-5k/edit#gid=0'
        : startdatumYear === 2024
        ? 'https://docs.google.com/spreadsheets/d/1mf6SBNPv8NNBUulHYNA1XGyKcPxrp3nsRz7Nyx6Har0/edit#gid=0'
        : startdatumYear === 2025
        ? 'https://docs.google.com/spreadsheets/d/1OkrSdCu1SskISUsioOWzJhi1M4lCqhAtScsvcdFMrWo/edit?gid=0#gid=0'
        : null;
    },
    reisezeitraumComputed() {
      if (!this.reiseterminObj) return '-';
      const reisedauer = this.reiseterminObj?.reisedauer ? this.reiseterminObj?.reisedauer - 1 : 0;
      const momentUntilDate = moment(this.reiseterminObj.abreisedatum).add(reisedauer, 'days');
      return this.reiseterminObj?.abreisedatum
        ? `${this.$options.filters.date(this.reiseterminObj.abreisedatum)} -  ${this.$options.filters.date(
            momentUntilDate
          )}`
        : '-';
    },
  },
  methods: {
    vorzeitigGarantiertClass,
    getInBlankAngelegt,
    onAufgabeBearbeitet(response) {
      this.$emit('aufgabeBearbeitet', response);
    },
    onClickAufgabenWidget(aufgabe) {
      this.$store.commit(SET_AUFGABE_DETAIL_DATA, aufgabe);
      this.$root.$emit('bv::show::modal', 'modalAufgabeDetails');
    },
    getStateGerman(value) {
      return value !== null && value !== undefined ? this.$t('REISETERMINE.states.' + value) : value;
    },
    formattedReleasefrist(datum, releasefrist) {
      const startdatum = new Date(datum);
      const generatedDatum = subDays(startdatum, releasefrist);
      return format(generatedDatum, 'dd.MM.yyyy');
    },
    formatReleasefristen(releasefristenArray, startdatum) {
      if (releasefristenArray.length > 0) {
        return releasefristenArray
          .map(releasefrist => `${releasefrist} (${this.formattedReleasefrist(startdatum, releasefrist)})`)
          .join(' / ');
      } else {
        return `${'-'}`;
      }
    },
  },
};
</script>
<style>
.tooltip {
  opacity: 1 !important;
}

.custom-tooltip > .tooltip-inner {
  max-width: none;
}
</style>
